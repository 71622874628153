import React, { Component } from 'react';
import Aux from '../../../hoc/_Aux/_Aux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Images } from '../Images/Images';
import { ChicagoData } from '../../../data/app_data';
import chicagoHero from '../../../assets/images/architecture/chicago/chicago___4.jpg';

const Studio = props => {
	let ChicagoImages = ChicagoData.map((item, index) => {
		return <Images item={item} key={index} />;
	});

		return (
			<Aux>
				<Helmet>
					<title>LIC - Chicago</title>
					<meta name="description" content="Chicago" />
				</Helmet>
				  <div className="intro w1">
					<h4 className="title ">NIKE CHICAGO - HOUSE OF SPORT</h4>
			    <h1 className="brandDescription ">669 N Michigan Avenue
Chicago, IL 60611
</h1>
			    <div className="categories">
			<h5>STORE DESIGN</h5>
			      <h5>3D MODELING </h5>
			      <h5>TECHNICAL DRAWINGS</h5>
			      <h5>RENDER</h5>
			    </div>
				  </div>
					<img src={chicagoHero} width="100%" alt="Athletic Studio" />
				  <div className="boundingColor">
					<h2 className="categoryTitle blackText w2">The Project <span>Completed 2019</span></h2>
				  <p className="projectCopy blackText w2 m-b-60">
Nike Chicago store needed a refresh after being remodeled 8 years ago. The objective was to improve the movement and category flow to enhance the consumer experience. Our design goal was to transform the store environment from Fieldhouse to House of Sport and evolve the store to reflect the consumer mindsets within the Chicago integrated marketplace. I was involved with the initial store concept storytelling and worked closely the visual merchandising team and space planning laying out the apparel and footwear fixtures.  I also handed off store architecture DI, redlining drawings sets and lead fixture design from initial DI through installs. 								</p>
				</div>
				    {ChicagoImages}
				<div className="l-w0 m-mobileProjLink">
					<Link className="m-projLink" to="/virgil">
					Next Project
					</Link>
				</div>
			</Aux>
		);
	};

export default Studio;
