import React from "react";
import Aux from "../../../hoc/_Aux/_Aux";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { carriage2Data} from "../../../data/app_data";
import { Images } from '../Images/Images';
import carriage2Hero from '../../../assets/images/architecture/carriage2/ch---15.jpg';


const carriageHouse2 = props => {
  let carriageHouse2Images = carriage2Data.map((item, index) => {
		return <Images item={item} key={index} />;
	});

  return (
    <Aux>
      <Helmet>
        <title>LIC - Carriage House</title>
        <meta name="description" content="Carriage House" />
      </Helmet>
        <div className="intro w1">
          <h4 className="title ">CARRIAGE HOUSE</h4>
          <h1 className="brandDescription ">ARLINGTON HEIGHTS PORTLAND OR</h1>
          <div className="categories">
            <h5>3D MODELING </h5>
            <h5>TECHNICAL DRAWINGS</h5>
            <h5>TRADES</h5>
            <h5>FINISHES</h5>
          </div>
        </div>
        <img src={carriage2Hero} width="100%" alt="Athletic Studio" />
        <div className="boundingColor">
        <h2 className="categoryTitle blackText w2">The Project <span>Completed 2021</span></h2>
        <p className="projectCopy blackText w2 m-b-60">
         Our Carriage House began as a project and we didn't think we would be doing work too. In digging up some issues with the house we decided to replace a number of the systems of the house. My partner and I went to Architecture school together, but lacked this hands-on experience. What became a learning process of all aspects of construction evolved be something we're very proud of. Having been heavily involved in all phases and all trades, we can say we really enjoyed the project. this project involves working with custom fabricators and even building the carriage doors and kitchen ourselves. With such a tight space our designs needed to be compact and seamless. My partner and I produced the architectural drawing set and worked with a structural engineer to complete the permitting set.</p>
      </div>
          {carriageHouse2Images}
      <div className="l-w0 m-mobileProjLink">
        <Link className="m-projLink" to="/sushibento">
          Next Project
        </Link>
      </div>
    </Aux>
  );
};

export default carriageHouse2;
