import React, { Component } from 'react';
import Aux from '../../../hoc/_Aux/_Aux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Images } from '../Images/Images';
import { SohoData } from '../../../data/app_data';
import SohoHero from '../../../assets/images/architecture/soho/soho___1.jpg';

const Soho = props => {
	let SohoImages = SohoData.map((item, index) => {
		return <Images item={item} key={index} />;
	});

		return (
			<Aux>
				<Helmet>
					<title>LIC - Soho</title>
					<meta name="description" content="Soho" />
				</Helmet>
				  <div className="intro w1">
					<h4 className="title ">NIKE SOHO - BEACON</h4>
			    <h1 className="brandDescription ">529 Broadway Street <br/>
New York, NY 10012
</h1>
				    <div className="categories">
						<h5>STORE DESIGN</h5>
						<h5>3D MODELING </h5>
						<h5>TECHNICAL DRAWINGS</h5>
						<h5>RENDER</h5>
				  </div>
					</div>
					<img src={SohoHero} width="100%" alt="Soho Retail" />
					<div className="boundingColor">
				  <h2 className="categoryTitle blackText w2">The Project <span>Completed 2016</span></h2>
				  <p className="projectCopy blackText m-b-60 w2">
					Nike’s flagship Soho location, occupying an entire 5-storey, 55,000 SF building, was designed to seamlessly bridge Nike’s digital and physical platforms. This building was designed from the ground up to suit the needs of Nike, incorporating cutting-edge retail experiences designed for customers to test products in real sports environments. The store includes a 400 SF synthetic turf field, a 23’ tall basketball retail space with a hardwood basketball trial zone, as well as NikeiD area for custom printing and laser engraving. My role on this project included envisioning design concepts, producing details and technical drawings, creating renderings, and collaborating with our team of in house designers as well as external consultants and contractors to see our designs all the way through the construction process.<br/><br/>

While working on Nike’s NYC Soho store, I was able to focus on the design of the building’s main entrance, which included the custom seating, cash wrap, back wrap, and digital service area. These complex seating elements needed to draw customers in, provide a space to sit, an “Instagram moment”, and serve as a permanent platform for seasonal window displays. <br/><br/>

Our team found inspiration in the nearby High Line park, and chose to incorporate similar elongated stepped seating into the space. These steps were then rotated at an angle to face into the space, towards the Women’s category. The wood grain is also on a bias leading your eye up into the atrium. To bring cohesion to the space, a lit reveal was added to the underside of each stair to match the lighting on the side of the escalators. This study of the stadium seating and one-on-one service kiosk study is a glimpse at the many process iterations which helped identify the specifics and characteristics of the final design.
<br/><br/>
						(Renders by Nike Visual Team) </p>
				</div>
				    {SohoImages}
				<div className="l-w0 m-mobileProjLink">
					<Link className="m-projLink" to="/athletic">
					Next Project
					</Link>
				</div>
			</Aux>
		);
	};

export default Soho;
