import React, { Component } from 'react';
import Aux from '../../../hoc/_Aux/_Aux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Images } from '../Images/Images';
import { SushiBentoData } from '../../../data/app_data';
import SushiBentoHero from '../../../assets/images/architecture/sushibento/sushibento-1.jpg';

const SushiBento = props => {
	let SushiBentoImages = SushiBentoData.map((item, index) => {
		return <Images item={item} key={index} />;
	});

		return (
			<Aux>
				<Helmet>
					<title>LIC - Sushi Bento</title>
					<meta name="description" content="SushiBento" />
				</Helmet>
				  <div className="intro w1">
					<h4 className="title ">DESIGN</h4>
			    <h1 className="brandDescription ">Furniture Projects</h1>
				    <div className="categories">
						<h5>STORE DESIGN</h5>
						<h5>3D MODELING </h5>
						<h5>TECHNICAL DRAWINGS</h5>
						<h5>RENDER</h5>
				  </div>
					</div>
					<img src={SushiBentoHero} width="100%" alt="SushiBento Retail" />
					<div className="boundingColor">
				  <h2 className="categoryTitle blackText w2">The Project</h2>
				  <p className="projectCopy blackText w2 m-b-60">The Sushi Bento, a super-ellipse shaped furniture piece, is a creation inspired by the furniture pieces of Hella Jongerius’ Backpack Sofa and Doshi Levien’s Chandlo. It offers the city dweller multiple possibilities for use in a small living space or studio. This experimental piece of furniture is designed for multiple uses in a compact form and can be transformed into an ottoman, a chair, a table for your laptop, an inflatable mat with a pillow and blanket, or a lowered Japanese style seat.</p>
				</div>
				    {SushiBentoImages}
				<div className="l-w0 m-mobileProjLink">
					<Link className="m-projLink" to="/fwd">
					Next Project
					</Link>
				</div>
			</Aux>
		);
	};

export default SushiBento;
