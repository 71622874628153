import React, { Component } from 'react';
import Aux from '../../../hoc/_Aux/_Aux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Images } from '../Images/Images';
import { chipegData } from '../../../data/app_data';
import chipegHero from '../../../assets/images/architecture/chipeg/chipeg___1.jpg';

const Studio = props => {
	let ChiPegImages = chipegData.map((item, index) => {
		return <Images item={item} key={index} />;
	});

		return (
			<Aux>
				<Helmet>
					<title>LIC - ChiPeg</title>
					<meta name="description" content="Chicago" />
				</Helmet>
				  <div className="intro w1">
					<h4 className="title ">NIKE CHICAGO - Peg41</h4>
			    <h1 className="brandDescription ">669 N Michigan Avenue
Chicago, IL 60611
</h1>
			    <div className="categories">
			<h5>STORE DESIGN</h5>
			      <h5>3D MODELING </h5>
			      <h5>TECHNICAL DRAWINGS</h5>
			      <h5>RENDER</h5>
			    </div>
				  </div>
					<img src={chipegHero} width="100%" alt="Athletic Studio" />
				  <div className="boundingColor">
					<h2 className="categoryTitle blackText w2">The Project <span>Completed 2024</span></h2>
				  <p className="projectCopy blackText w2 m-b-60">
TKTKTKTKTKTK <br/>
TKTKTKTKTKTK <br/>
TKTKTKTKTKTK <br/>
TKTKTKTKTKTK <br/>
TKTKTKTKTKTK <br/>	
TKTKTKTKTKTK 							</p>
				</div>
				    {ChiPegImages}
				<div className="l-w0 m-mobileProjLink">
					<Link className="m-projLink" to="/virgil">
					Next Project
					</Link>
				</div>
			</Aux>
		);
	};

export default Studio;
