import React, { Component } from 'react';
import Aux from '../../../hoc/_Aux/_Aux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Images } from '../Images/Images';
import { SanFranciscoData } from '../../../data/app_data';
import SanFraniscoHero from '../../../assets/images/architecture/sf/sf___3.jpg';

const SanFrancisco = props => {
	let SanFranciscoImages = SanFranciscoData.map((item, index) => {
		return <Images item={item} key={index} />;
	});

		return (
			<Aux>
				<Helmet>
					<title>LIC - SanFrancisco</title>
					<meta name="description" content="San Francisco" />
				</Helmet>
				  <div className="intro w1">
				    <h4 className="title ">NIKE SAN FRANCISCO - HOUSE OF SPORT</h4>
				    <h1 className="brandDescription ">278 Post St	<br/>
San Francisco, CA 94108
</h1>
				    <div className="categories">
				      <h5>3D MODELING </h5>
				      <h5>TECHNICAL DRAWINGS</h5>
				      <h5>FURNITURE</h5>
				      <h5>RENDER</h5>
				    </div>
				  </div>
				  <img src={SanFraniscoHero} width="100%" alt="Athletic Studio" />
				  <div className="boundingColor">
				  <h2 className="categoryTitle blackText w2">The Project <span>Completed 2019</span></h2>
				  <p className="projectCopy blackText w2 m-b-60">
				   The Nike San Francisco store was the second multi-level store refresh and transformation of the store environment from Fieldhouse to House of Sport. The objective was to evolve the store to reflect the consumer mindsets within the San Francisco integrated marketplace. Our design goal was to update service areas to be more consumer-right, specifically fitting rooms, cashwrap and service desk. I was involved with the initial store concept storytelling and worked closely the visual merchandising team and space planning laying out the apparel and footwear fixtures.  lead fixture design from initial DI through installs and was the main store designer on site through store opening.</p>
				</div>
				    {SanFranciscoImages}
				<div className="l-w0 m-mobileProjLink">
					<Link className="m-projLink" to="/chicago">
					Next Project
					</Link>
				</div>
			</Aux>
		);
	};

export default SanFrancisco;
