import React, { Component } from 'react';
import Aux from '../../../hoc/_Aux/_Aux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Images } from '../Images/Images';
import { UESData } from '../../../data/app_data';
import UESHero from '../../../assets/images/architecture/ues/ues___3.jpg';

const UES = props => {
	let UESImages = UESData.map((item, index) => {
		return <Images item={item} key={index} />;
	});

		return (
			<Aux>
				<Helmet>
					<title>LIC - UES</title>
					<meta name="description" content="Upper East Side" />
				</Helmet>
				  <div className="intro w1">
				    <h4 className="title ">NIKE UPPER EAST SIDE - LIVE</h4>
				    <h1 className="brandDescription ">1131 3rd Ave<br/>
New York, NY 10065
</h1>
				    <div className="categories">
				      <h5>3D MODELING </h5>
				      <h5>TECHNICAL DRAWINGS</h5>
				      <h5>FURNITURE</h5>
				      <h5>RENDER</h5>
				    </div>
				  </div>
				  <img src={UESHero} width="100%" alt="Athletic Studio" />
				  <div className="boundingColor">
				  <h2 className="categoryTitle blackText w2">The Project <span>Completed 2020</span></h2>
				  <p className="projectCopy blackText w2 m-b-60">
					This project is the first New York-based Live concept store, a new type of Nike store intended to exist at the neighborhood level. These typically have a smaller footprint and a more residential feel. The store environment is laid out like a home, with a Welcome area followed by the living room, closet, and kitchen. <br/><br/>

					Located in the upper Eastside neighborhood, this existing Nike store is on the first floor of a condo building. Reinventing the space to match the new Nike Live rollout concept was a challenge, as the space was quite unique. Its unique characteristics included two entries, low ceilings, and 40+ structural columns (on an irregular column grid) in the sales floor alone. With a relatively tight budget, we needed to leverage the existing store condition to keep construction to a minimum.<br/><br/>

					I was the lead designer for this project, from insight to concept to execution. The beginning phases included collaborating with space planning and visual merchandising to lay out the space. Beyond my typical design responsibilities, I also collaborated with environmental graphic design on the rugs and curtains, designed by a local artist to feel of the place.<br/><br/>

					This project was in partnership with Nike’s internal Store Design, Visual Merchandising, Brand Marketing (NSO and Live), Space Planning, Environmental Graphic Design teams as well as external teams including Superette (Architect of Record) and RTC (fixture vendor). Collaboration with the construction teams was a special challenge on this project as this project began after COVID-19 had become a pandemic, our team pivoted to digital collaboration mediums, including virtual site visits and zoom meetings for check sheets.</p>				</div>
				    {UESImages}
				<div className="l-w0 m-mobileProjLink">
					<Link className="m-projLink" to="/Eugene">
					Next Project
					</Link>
				</div>
			</Aux>
		);
	};

export default UES;
