import React, { Component } from 'react';

import Homeheader from '../../components/Header/HomeHeader';
import Homeaux from '../_Aux/_Homeaux';

class homelayout extends Component {
	handleToggle = () => {
		document.getElementById('l-fixedMenu').classList.toggle('m-fullHeight');
		document.getElementById('llmobileDropdown').classList.toggle('showed');
		document.getElementById('mainBody').classList.toggle('m-locked');
	};
	render() {
		return (
			<Homeaux>
				<Homeheader handleToggle={this.handleToggle} />
				<Homeaux>{this.props.children}</Homeaux>
			</Homeaux>
		);
	}
}

export default homelayout;
