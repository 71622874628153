// Bento Data

export const SushiBentoData = [
  {
    name: 'Img4',
    class: "webPhoto",
    imagesrc: "images/architecture/sushibento/sushibento-2.jpg",
    alt: "X"
  }, {
    name: 'Img4',
    class: "webPhoto",
    imagesrc: "images/architecture/sushibento/sushibento-3.jpg",
    alt: "X"
  }, {
    name: 'Img4',
    class: "webPhoto",
    imagesrc: "images/architecture/sushibento/sushibento-4.jpg",
    alt: "X"
  },{
  name: 'Img4',
  class: "webPhoto",
  imagesrc: "images/architecture/shousugiban/shousugiban___2.jpg",
  alt: "X"
  }, {
  name: 'Img4',
  class: "webPhoto",
  imagesrc: "images/architecture/shousugiban/shousugiban___3.jpg",
  alt: "X"
  },
];

// Carriage Data

export const CarriageData = [
{
    name: 'Img4',
    class: "webPhoto",
    imagesrc: "images/architecture/carriage/carriage-2.jpg",
    alt: "X"
  }, {
    name: 'Img1',
    class: "webPhoto",
    imagesrc: "images/architecture/carriage/carriage-3.jpg",
    alt: "X"
  }, {
    name: 'Img2',
    class: "webPhoto",
    imagesrc: "images/architecture/carriage/carriage-4.jpg",
    alt: "X"
  }, {
    name: 'Img3',
    class: "webPhoto",
    imagesrc: "images/architecture/carriage/carriage-5.jpg",
    alt: "X"
  }, {
    name: 'Img6',
    class: "webPhoto",
    imagesrc: "images/architecture/carriage/carriage-6.jpg",
    alt: "X"
  }, {
    name: 'Img7',
    class: "webPhoto",
    imagesrc: "images/architecture/carriage/carriage-7.jpg",
    alt: "X"
  }, {
    name: 'Img8',
    class: "webPhoto",
    imagesrc: "images/architecture/carriage/carriage-8.jpg",
    alt: "X"
  },
];


// Kiddom Data

export const KiddomData = [
  {
    name: 'Img7',
    class: "webPhoto",
    imagesrc: "images/architecture/kiddom/kiddom___1.jpg",
    alt: "X"
  }, {
    name: 'Img2',
    class: "webPhoto",
    imagesrc: "images/architecture/kiddom/kiddom___3.jpg",
    alt: "X"
  }, {
    name: 'Img1',
    class: "webPhoto",
    imagesrc: "images/architecture/kiddom/kiddom___4.jpg",
    alt: "X"
  }, {
    name: 'Img3',
    class: "webPhoto",
    imagesrc: "images/architecture/kiddom/kiddom___5.jpg",
    alt: "X"
  }, {
    name: 'Img5',
    class: "webPhoto",
    imagesrc: "images/architecture/kiddom/kiddom___6.jpg",
    alt: "X"
  }, {
    name: 'Img3',
    class: "webPhoto",
    imagesrc: "images/architecture/kiddom/kiddom___7.jpg",
    alt: "X"
  }, {
    name: 'Img3',
    class: "webPhoto",
    imagesrc: "images/architecture/kiddom/kiddom___8.jpg",
    alt: "X"
  }, {
    name: 'Img3',
    class: "webPhoto",
    imagesrc: "images/architecture/kiddom/kiddom___9.jpg",
    alt: "X"
  }, {
    name: 'Img3',
    class: "webPhoto",
    imagesrc: "images/architecture/kiddom/kiddom___10.jpg",
    alt: "X"
  }, {
    name: 'Img3',
    class: "webPhoto",
    imagesrc: "images/architecture/kiddom/kiddom___11.jpg",
    alt: "X"
  }, {
    name: 'Img3',
    class: "webPhoto",
    imagesrc: "images/architecture/kiddom/kiddom___12.jpg",
    alt: "X"
  }, {
    name: 'Img3',
    class: "webPhoto",
    imagesrc: "images/architecture/kiddom/kiddom___13.jpg",
    alt: "X"
  },
];

// Athletic Data

export const AthleticData = [
{
    name: 'Img1',
    class: "webPhoto",
    imagesrc: "images/architecture/athletic/athletic-2.jpg",
    alt: "X"
  }, {
    name: 'Img4',
    class: "webPhoto",
    imagesrc: "images/architecture/athletic/athletic-3.jpg",
alt: "X"
  }, {
    name: 'Img4',
    class: "webPhoto",
    imagesrc: "images/architecture/athletic/athletic-5.png",
    alt: "X"
  },
];

// Cheese Data

export const CheeseData = [
  {
    name: 'Img4',
    class: "webPhoto",
    imagesrc: "images/architecture/cheese/cheese-2.jpg",
    alt: "X"
  }, {
    name: 'Img4',
    class: "webPhoto",
    imagesrc: "images/architecture/cheese/cheese-3.jpg",
    alt: "X"
  }, {
    name: 'Img4',
    class: "webPhoto",
    imagesrc: "images/architecture/cheese/cheese-4.jpg",
    alt: "X"
  }, {
    name: 'Img4',
    class: "webPhoto",
    imagesrc: "images/architecture/cheese/cheese-5.jpg",
    alt: "X"
  }, {
    name: 'Img4',
    class: "webPhoto",
    imagesrc: "images/architecture/cheese/cheese-6.jpg",
    alt: "X"
  }, {
    name: 'Img4',
    class: "webPhoto",
    imagesrc: "images/architecture/cheese/cheese-7.jpg",
    alt: "X"
  }, {
    name: 'Img4',
    class: "webPhoto",
    imagesrc: "images/architecture/cheese/cheese-8.jpg",
    alt: "X"
  }, {
    name: 'Img4',
    class: "webPhoto",
    imagesrc: "images/architecture/cheese/cheese-9.jpg",
    alt: "X"
  },
];

// FWD Data

export const FWDData = [
  {
      name: 'Img4',
      class: "webPhoto",
      imagesrc: "images/architecture/fwd/fwd___1.jpg",
      alt: "X"
    }, {
      name: 'Img4',
      class: "webPhoto",
      imagesrc: "images/architecture/fwd/fwd___2.jpg",
      alt: "X"
    }, {
      name: 'Img4',
      class: "webPhoto",
      imagesrc: "images/architecture/fwd/fwd___3.jpg",
      alt: "X"
    }, {
      name: 'Img4',
      class: "webPhoto",
      imagesrc: "images/architecture/fwd/fwd___4.jpg",
      alt: "X"
    }, {
      name: 'Img4',
      class: "webPhoto",
      imagesrc: "images/architecture/fwd/fwd___5.jpg",
    }, {
      name: 'Img4',
      class: "webPhoto",
      imagesrc: "images/architecture/fwd/fwd___6.jpg",
      alt: "X"
    }, {
      name: 'Img4',
      class: "webPhoto",
      imagesrc: "images/architecture/fwd/fwd___7.jpg",
      alt: "X"
    }, {
      name: 'Img4',
      class: "webPhoto",
      imagesrc: "images/architecture/fwd/fwd___8.jpg",
      alt: "X"
    },
  ];

// Rucksack Data

export const RucksackData = [
  {
      name: 'Img4',
      class: "webPhoto",
      imagesrc: "images/furniture/rucksack/rucksack-1.jpg",
      alt: "X"
    }, {
      name: 'Img4',
      class: "webPhoto",
      imagesrc: "images/furniture/rucksack/rucksack-3.png",
      alt: "X"
    }, {
      name: 'Img4',
      class: "webPhoto",
      imagesrc: "images/furniture/rucksack/rucksack-4.png",
      alt: "X"
    }, {
      name: 'Img4',
      class: "webPhoto",
      imagesrc: "images/furniture/rucksack/rucksack-5.png",
      alt: "X"
    }, {
      name: 'Img4',
      class: "webPhoto",
      imagesrc: "images/furniture/rucksack/rucksack-6.png",
      alt: "X"
    },
  ];

// Chicago Data

export const ChicagoData = [
  {
      name: 'Img4',
      class: "webPhoto",
      imagesrc: "images/architecture/chicago/chicago___2.jpg",
      alt: "X"
    }, {
      name: 'Img4',
      class: "webPhoto",
      imagesrc: "images/architecture/chicago/chicago___3.jpg",
      alt: "X"
    }, {
      name: 'Img4',
      class: "webPhoto",
      imagesrc: "images/architecture/chicago/chicago___4.jpg",
      alt: "X"
    },{
      name: 'Img4',
      class: "webPhoto",
      imagesrc: "images/architecture/chicago/chicago___14.jpg",
      alt: "X"
    },{
     name: 'Img4',
     class: "webPhoto",
     imagesrc: "images/architecture/chicago/chicago___1.jpg",
     alt: "X"
   },{
      name: 'Img4',
      class: "webPhoto",
      imagesrc: "images/architecture/chicago/chicago___5.jpg",
      alt: "X"
    }, {
      name: 'Img4',
      class: "webPhoto",
      imagesrc: "images/architecture/chicago/chicago___6.jpg",
      alt: "X"
    },{
      name: 'Img4',
      class: "webPhoto",
      imagesrc: "images/architecture/chicago/chicago___7.jpg",
      alt: "X"
    }, {
      name: 'Img4',
      class: "webPhoto",
      imagesrc: "images/architecture/chicago/chicago___8.jpg",
      alt: "X"
    },{
    name: 'Img4',
    class: "webPhoto",
    imagesrc: "images/architecture/chicago/chicago___fp1.jpg",
    alt: "X"
    },{
    name: 'Img4',
    class: "webPhoto",
    imagesrc: "images/architecture/chicago/chicago___fp2.jpg",
    alt: "X"
    },{
    name: 'Img4',
    class: "webPhoto",
    imagesrc: "images/architecture/chicago/chicago___fp3.jpg",
    alt: "X"
    },{
    name: 'Img4',
    class: "webPhoto",
    imagesrc: "images/architecture/chicago/chicago___fp4.jpg",
    alt: "X"
    }
  ];

  // chipeg Data

export const chipegData = [
  {
      
      name: 'Img4',
      class: "webPhoto",
      imagesrc: "images/architecture/chipeg/chipeg___4.jpg",
      alt: "X"
    },{
     name: 'Img4',
     class: "webPhoto",
     imagesrc: "images/architecture/chipeg/chipeg___5.jpg",
     alt: "X"
   },{
      name: 'Img4',
      class: "webPhoto",
      imagesrc: "images/architecture/chipeg/chipeg___6.jpg",
      alt: "X"
    }, {
      name: 'Img4',
      class: "webPhoto",
      imagesrc: "images/architecture/chipeg/chipeg___7.jpg",
      alt: "X"
    },{
      name: 'Img4',
      class: "webPhoto",
      imagesrc: "images/architecture/chipeg/chipeg___8.jpg",
      alt: "X"
    }, {
      name: 'Img4',
      class: "webPhoto",
      imagesrc: "images/architecture/chipeg/chipeg___9.jpg",
      alt: "X"
    },{
    name: 'Img4',
    class: "webPhoto",
    imagesrc: "images/architecture/chipeg/chipeg___10.jpg",
    alt: "X"
    },{
    name: 'Img4',
    class: "webPhoto",
    imagesrc: "images/architecture/chipeg/chipeg___11.jpg",
    alt: "X"
    },{
    name: 'Img4',
    class: "webPhoto",
    imagesrc: "images/architecture/chipeg/chipeg___2.jpg",
    alt: "X"
  }, {
    name: 'Img4',
    class: "webPhoto",
    imagesrc: "images/architecture/chipeg/chipeg___3.jpg",
    alt: "X"
  },{
    name: 'Img4',
    class: "webPhoto",
    imagesrc: "images/architecture/chipeg/chipeg___12.jpg",
    alt: "X"
    }
  ];

// Soho Data

export const SohoData = [
{
        name: 'Img4',
        class: "webPhoto",
        imagesrc: "images/architecture/soho/soho-12.png",
        alt: "X"
      },{
        name: 'Img4',
        class: "webPhoto",
        imagesrc: "images/architecture/soho/soho-13.png",
        alt: "X"
      },{
        name: 'Img4',
        class: "webPhoto",
        imagesrc: "images/architecture/soho/soho-14.png",
        alt: "X"
      },{
          name: 'Img4',
          class: "webPhoto",
          imagesrc: "images/architecture/soho/soho-11.png",
          alt: "X"
    },{
      name: 'Img4',
      class: "webPhoto",
      imagesrc: "images/architecture/soho/soho___5.jpg",
      alt: "X"
    },{
        name: 'Img4',
        class: "webPhoto",
        imagesrc: "images/architecture/soho/soho___2.jpg",
        alt: "X"
      }, {
          name: 'Img4',
          class: "webPhoto",
          imagesrc: "images/architecture/soho/soho___3.jpg",
          alt: "X"
        },{  name: 'Img4',
          class: "webPhoto",
          imagesrc: "images/architecture/soho/soho___4.jpg",
          alt: "X"
        }, {
      name: 'Img4',
      class: "webPhoto",
      imagesrc: "images/architecture/soho/soho___6.jpg",
      alt: "X"
    }, {
      name: 'Img4',
      class: "webPhoto",
      imagesrc: "images/architecture/soho/soho___7.jpg",
      alt: "X"
    }, {
      name: 'Img4',
      class: "webPhoto",
      imagesrc: "images/architecture/soho/soho___8.jpg",
      alt: "X"
    }, {
      name: 'Img4',
      class: "webPhoto",
      imagesrc: "images/architecture/soho/soho___9.jpg",
      alt: "X"
    }, {
          name: 'Img4',
          class: "webPhoto",
          imagesrc: "images/architecture/soho/soho___1F.jpg",
          alt: "X"
        }, {
          name: 'Img4',
          class: "webPhoto",
          imagesrc: "images/architecture/soho/soho___2F.jpg",
          alt: "X"
        }, {
          name: 'Img4',
          class: "webPhoto",
          imagesrc: "images/architecture/soho/soho___3F.jpg",
          alt: "X"
        }, {
          name: 'Img4',
          class: "webPhoto",
          imagesrc: "images/architecture/soho/soho___4F.jpg",
          alt: "X"
        }, {
          name: 'Img4',
          class: "webPhoto",
          imagesrc: "images/architecture/soho/soho___5F.jpg",
          alt: "X"
        }

  ];

export const VirgilData = [
{
name: 'Img4',
class: "webPhoto",
imagesrc: "images/architecture/virgil/virgil___6.jpg",
alt: "X"
},{
name: 'Img4',
class: "webPhoto",
imagesrc: "images/architecture/virgil/virgil___7.jpg",
alt: "X"
},{
name: 'Img4',
class: "webPhoto",
imagesrc: "images/architecture/virgil/virgil___12.jpg",
alt: "X"
},

{
name: 'Img4',
class: "webPhoto",
imagesrc: "images/architecture/virgil/virgil___13.jpg",
alt: "X"
},{
name: 'Img4',
class: "webPhoto",
imagesrc: "images/architecture/virgil/virgil___14.jpg",
alt: "X"
},{
name: 'Img4',
class: "webPhoto",
imagesrc: "images/architecture/virgil/virgil___1.jpg",
alt: "X"
}, {
name: 'Img4',
class: "webPhoto",
imagesrc: "images/architecture/virgil/virgil___2.jpg",
alt: "X"
}, {
name: 'Img4',
class: "webPhoto",
imagesrc: "images/architecture/virgil/virgil___3.jpg",
alt: "X"
}, {
name: 'Img4',
class: "webPhoto",
imagesrc: "images/architecture/virgil/virgil___4.jpg",
alt: "X"
},
{
name: 'Img4',
class: "webPhoto",
imagesrc: "images/architecture/virgil/virgil___5.jpg",
alt: "X"
},{
name: 'Img4',
class: "webPhoto",
imagesrc: "images/architecture/virgil/virgil___8.jpg",
alt: "X"
},{
name: 'Img4',
class: "webPhoto",
imagesrc: "images/architecture/virgil/virgil___9.jpg",
alt: "X"
},

{
name: 'Img4',
class: "webPhoto",
imagesrc: "images/architecture/virgil/virgil___10.jpg",
alt: "X"
},

{
name: 'Img4',
class: "webPhoto",
imagesrc: "images/architecture/virgil/virgil___11.jpg",
alt: "X"
},

];

export const SanFranciscoData = [
  {
 name: 'Img4',
 class: "webPhoto",
 imagesrc: "images/architecture/sf/sf___2.jpg",
 alt: "X"
 }, {
name: 'Img4',
class: "webPhoto",
imagesrc: "images/architecture/sf/sf___1.jpg",
alt: "X"
},{
name: 'Img4',
class: "webPhoto",
imagesrc: "images/architecture/sf/details___2.jpg",
alt: "X"
}, {
name: 'Img4',
class: "webPhoto",
imagesrc: "images/architecture/sf/details___3.jpg",
alt: "X"
},
{
name: 'Img4',
class: "webPhoto",
imagesrc: "images/architecture/sf/sf___4.jpg",
alt: "X"
}, {
name: 'Img4',
class: "webPhoto",
imagesrc: "images/architecture/sf/sf___5.jpg",
alt: "X"
}, {
name: 'Img4',
class: "webPhoto",
imagesrc: "images/architecture/sf/sf___8.jpg",
alt: "X"
},{
name: 'Img4',
class: "webPhoto",
imagesrc: "images/architecture/sf/sf___9.jpg",
alt: "X"
}, {
name: 'Img4',
class: "webPhoto",
imagesrc: "images/architecture/sf/sf___6.jpg",
alt: "X"
},{
name: 'Img4',
class: "webPhoto",
imagesrc: "images/architecture/sf/sf___7.jpg",
alt: "X"
}, {
name: 'Img4',
class: "webPhoto",
imagesrc: "images/architecture/sf/sf___fp1.jpg",
alt: "X"
},{
name: 'Img4',
class: "webPhoto",
imagesrc: "images/architecture/sf/sf___fp3.jpg",
alt: "X"
},{
name: 'Img4',
class: "webPhoto",
imagesrc: "images/architecture/sf/sf___fp4.jpg",
alt: "X"
},{
name: 'Img4',
class: "webPhoto",
imagesrc: "images/architecture/sf/sf___fp5.jpg",
alt: "X"
},{
name: 'Img4',
class: "webPhoto",
imagesrc: "images/architecture/sf/sf___fp6.jpg",
alt: "X"
}
];
export const SherwayData = [
{
name: 'Img4',
class: "webPhoto",
imagesrc: "images/architecture/sherway/sherway___3.jpg",
alt: "X"
}, {
name: 'Img4',
class: "webPhoto",
imagesrc: "images/architecture/sherway/sherway___7.jpg",
alt: "X"
}, {
name: 'Img4',
class: "webPhoto",
imagesrc: "images/architecture/sherway/sherway___5.jpg",
alt: "X"
}, {
name: 'Img4',
class: "webPhoto",
imagesrc: "images/architecture/sherway/sherway___6.jpg",
alt: "X"
},{
name: 'Img4',
class: "webPhoto",
imagesrc: "images/architecture/sherway/sherway___sketch.jpg",
alt: "X"
}, {
name: 'Img4',
class: "webPhoto",
imagesrc: "images/architecture/sherway/sherway___8.jpg",
alt: "X"
},{
name: 'Img4',
class: "webPhoto",
imagesrc: "images/architecture/sherway/sherway___elevation.jpg",
alt: "X"
},{
name: 'Img4',
class: "webPhoto",
imagesrc: "images/architecture/sherway/sherway___detail.jpg",
alt: "X"
},{
name: 'Img4',
class: "webPhoto",
imagesrc: "images/architecture/sherway/sherway___2.jpg",
alt: "X"
}, {
name: 'Img4',
class: "webPhoto",
imagesrc: "images/architecture/sherway/sherway___4.jpg",
alt: "X"
},{
name: 'Img4',
class: "webPhoto",
imagesrc: "images/architecture/sherway/sherway___fp1.jpg",
alt: "X"
},
];

export const SQ1Data = [
  {
  name: 'Img4',
  class: "webPhoto",
  imagesrc: "images/architecture/sq1/sq1___3.jpg",
  alt: "X"
  },   {
    name: 'Img4',
    class: "webPhoto",
    imagesrc: "images/architecture/sq1/sq1___5.jpg",
    alt: "X"
    }, {
    name: 'Img4',
    class: "webPhoto",
    imagesrc: "images/architecture/sq1/sq1___6.jpg",
    alt: "X"
    },  {
    name: 'Img4',
    class: "webPhoto",
    imagesrc: "images/architecture/sq1/sq1___13.jpg",
    alt: "X"
    },{
    name: 'Img4',
    class: "webPhoto",
    imagesrc: "images/architecture/sq1/sq1___11.jpg",
    alt: "X"
    },{
  name: 'Img4',
  class: "webPhoto",
  imagesrc: "images/architecture/sq1/sq1___7.jpg",
  alt: "X"
  },{
name: 'Img4',
class: "webPhoto",
imagesrc: "images/architecture/sq1/sq1___8.jpg",
alt: "X"
},{
name: 'Img4',
class: "webPhoto",
imagesrc: "images/architecture/sq1/sq1___12.jpg",
alt: "X"
},{
  name: 'Img4',
  class: "webPhoto",
  imagesrc: "images/architecture/sq1/sq1___4.jpg",
  alt: "X"
  }, {
name: 'Img4',
class: "webPhoto",
imagesrc: "images/architecture/sq1/sq1___2.jpg",
alt: "X"
},
{
name: 'Img4',
class: "webPhoto",
imagesrc: "images/architecture/sq1/sq1___9.jpg",
alt: "X"
},{
name: 'Img4',
class: "webPhoto",
imagesrc: "images/architecture/sq1/sq1___10.jpg",
alt: "X"
},
];

export const EugeneData = [
  {
      name: 'Img4',
      class: "webPhoto",
      imagesrc: "images/architecture/eugene/eugene___1.jpg",
      alt: "X"
    }, {
      name: 'Img4',
      class: "webPhoto",
      imagesrc: "images/architecture/eugene/eugene___2.jpg",
      alt: "X"
    }, {
      name: 'Img4',
      class: "webPhoto",
      imagesrc: "images/architecture/eugene/eugene___3.jpg",
      alt: "X"
    },{
      name: 'Img4',
      class: "webPhoto",
      imagesrc: "images/architecture/eugene/eugene___4.jpg",
      alt: "X"
    },{
     name: 'Img4',
     class: "webPhoto",
     imagesrc: "images/architecture/eugene/eugene___5.jpg",
     alt: "X"
   },{
      name: 'Img4',
      class: "webPhoto",
      imagesrc: "images/architecture/eugene/eugene___6.jpg",
      alt: "X"
    }, {
      name: 'Img4',
      class: "webPhoto",
      imagesrc: "images/architecture/eugene/eugene___7.jpg",
      alt: "X"
    },{
      name: 'Img4',
      class: "webPhoto",
      imagesrc: "images/architecture/eugene/eugene___8.jpg",
      alt: "X"
    },{
    name: 'Img4',
    class: "webPhoto",
    imagesrc: "images/architecture/eugene/eugene___9.jpg",
    alt: "X"
  },{
    name: 'Img4',
    class: "webPhoto",
    imagesrc: "images/architecture/eugene/eugene___fp1.jpg",
    alt: "X"
    }
  ];

  export const UESData = [
    {
        name: 'Img4',
        class: "webPhoto",
        imagesrc: "images/architecture/ues/ues___1.jpg",
        alt: "X"
      }, {
        name: 'Img4',
        class: "webPhoto",
        imagesrc: "images/architecture/ues/ues___2.jpg",
        alt: "X"
      }, {
        name: 'Img4',
        class: "webPhoto",
        imagesrc: "images/architecture/ues/ues___3.jpg",
        alt: "X"
      },{
        name: 'Img4',
        class: "webPhoto",
        imagesrc: "images/architecture/ues/ues___4.jpg",
        alt: "X"
      },{
       name: 'Img4',
       class: "webPhoto",
       imagesrc: "images/architecture/ues/ues___5.jpg",
       alt: "X"
     },{
        name: 'Img4',
        class: "webPhoto",
        imagesrc: "images/architecture/ues/ues___6.jpg",
        alt: "X"
      }, {
        name: 'Img4',
        class: "webPhoto",
        imagesrc: "images/architecture/ues/ues___7.jpg",
        alt: "X"
      },{
        name: 'Img4',
        class: "webPhoto",
        imagesrc: "images/architecture/ues/ues___8.jpg",
        alt: "X"
      },{
      name: 'Img4',
      class: "webPhoto",
      imagesrc: "images/architecture/ues/ues___fp1.jpg",
      alt: "X"
      }
    ];
    export const AtlantaData = [
      {
          name: 'Img4',
          class: "webPhoto",
          imagesrc: "images/architecture/atlanta/atlanta___1.jpg",
          alt: "X"
        }, {
          name: 'Img4',
          class: "webPhoto",
          imagesrc: "images/architecture/atlanta/atlanta___2.jpg",
          alt: "X"
        }, {
          name: 'Img4',
          class: "webPhoto",
          imagesrc: "images/architecture/atlanta/atlanta___3.jpg",
          alt: "X"
        },{
          name: 'Img4',
          class: "webPhoto",
          imagesrc: "images/architecture/atlanta/atlanta___4.jpg",
          alt: "X"
        },{
         name: 'Img4',
         class: "webPhoto",
         imagesrc: "images/architecture/atlanta/atlanta___5.jpg",
         alt: "X"
       },{
          name: 'Img4',
          class: "webPhoto",
          imagesrc: "images/architecture/atlanta/atlanta___6.jpg",
          alt: "X"
        }, {
          name: 'Img4',
          class: "webPhoto",
          imagesrc: "images/architecture/atlanta/atlanta___7.jpg",
          alt: "X"
        },{
          name: 'Img4',
          class: "webPhoto",
          imagesrc: "images/architecture/atlanta/atlanta___8.jpg",
          alt: "X"
        }, {
          name: 'Img4',
          class: "webPhoto",
          imagesrc: "images/architecture/atlanta/atlanta___9.jpg",
          alt: "X"
        },{
          name: 'Img4',
          class: "webPhoto",
          imagesrc: "images/architecture/atlanta/atlanta___10.jpg",
          alt: "X"
        },{
          name: 'Img4',
          class: "webPhoto",
          imagesrc: "images/architecture/atlanta/atlanta___11.jpg",
          alt: "X"
        },{
          name: 'Img4',
          class: "webPhoto",
          imagesrc: "images/architecture/atlanta/atlanta___12.jpg",
          alt: "X"
        },{
          name: 'Img4',
          class: "webPhoto",
          imagesrc: "images/architecture/atlanta/atlanta___13.jpg",
          alt: "X"
        },{
          name: 'Img4',
          class: "webPhoto",
          imagesrc: "images/architecture/atlanta/atlanta___14.jpg",
          alt: "X"
        },{
        name: 'Img4',
        class: "webPhoto",
        imagesrc: "images/architecture/atlanta/atlanta___fp1.jpg",
        alt: "X"
        },{
        name: 'Img4',
        class: "webPhoto",
        imagesrc: "images/architecture/atlanta/atlanta___fp2.jpg",
        alt: "X"
        }
      ];

      export const carriage2Data = [{
            class: "webPhoto",
           imagesrc: "images/architecture/carriage2/ch---1.jpg",
           alt: "Carriage House"
       },{
         name: "Carriage House",
          class: "webPhoto",
          imagesrc: "images/architecture/carriage2/ch---2.jpg",
          alt: "Carriage House"
      }, {
          name: "Carriage House",
          class: "webPhoto",
          imagesrc: "images/architecture/carriage2/ch---3.jpg",
          alt: "Carriage House"
      }, {
          name: "Carriage House",
          class: "webPhoto",
          imagesrc: "images/architecture/carriage2/ch---4.jpg",
          alt: "Carriage House"
      }, {
          name: "Carriage House",
          class: "webPhoto",
          imagesrc: "images/architecture/carriage2/ch---5.jpg",
          alt: "Carriage House"
      },{
          name: "Carriage House",
          class: "webPhoto",
          imagesrc: "images/architecture/carriage2/ch---6.jpg",
          alt: "Carriage House"
      },{
          class: "webPhoto",
          imagesrc: "images/architecture/carriage2/ch---7.jpg",
          alt: "Carriage House"
      }, {
          class: "webPhoto",
          imagesrc: "images/architecture/carriage2/ch---8.jpg",
          alt: "Carriage House"
      }, {
          class: "webPhoto",
          imagesrc: "images/architecture/carriage2/ch---9.jpg",
          alt: "Carriage House"
      }, {
          class: "webPhoto",
          imagesrc: "images/architecture/carriage2/ch---10.jpg",
          alt: "Carriage House"
      },{
          class: "webPhoto",
          imagesrc: "images/architecture/carriage2/ch---11.jpg",
          alt: "Carriage House"
      }, {
          class: "webPhoto",
          imagesrc: "images/architecture/carriage2/ch---12.jpg",
          alt: "Carriage House"
      }, {
          class: "webPhoto",
          imagesrc: "images/architecture/carriage2/ch---13.jpg",
          alt: "Carriage House"
      }, {
          class: "webPhoto",
          imagesrc: "images/architecture/carriage2/ch---14.jpg",
          alt: "Carriage House"
      },{
          class: "webPhoto",
          imagesrc: "images/architecture/carriage2/ch---15.jpg",
          alt: "Carriage House"
      },{
          class: "webPhoto",
          imagesrc: "images/architecture/carriage2/ch---16.jpg",
          alt: "Carriage House"
      }, {
          class: "webPhoto",
          imagesrc: "images/architecture/carriage2/ch---17.jpg",
          alt: "Carriage House"
      }, {
          class: "webPhoto",
          imagesrc: "images/architecture/carriage2/ch---18.jpg",
          alt: "Carriage House"
        }, {
            class: "webPhoto",
            imagesrc: "images/architecture/carriage2/ch---19.jpg",
            alt: "Carriage House"
          }, {
              class: "webPhoto",
              imagesrc: "images/architecture/carriage2/ch---20.jpg",
              alt: "Carriage House"
      }];

      export const marketmallData = [
        {
            name: 'Img4',
            class: "webPhoto",
            imagesrc: "images/architecture/marketmall/marketmall___1.jpg",
            alt: "X"
          }, {
            name: 'Img4',
            class: "webPhoto",
            imagesrc: "images/architecture/marketmall/marketmall___2.jpg",
            alt: "X"
          }, {
            name: 'Img4',
            class: "webPhoto",
            imagesrc: "images/architecture/marketmall/marketmall___3.jpg",
            alt: "X"
          },{
            name: 'Img4',
            class: "webPhoto",
            imagesrc: "images/architecture/marketmall/marketmall___4.jpg",
            alt: "X"
          },{
           name: 'Img4',
           class: "webPhoto",
           imagesrc: "images/architecture/marketmall/marketmall___5.jpg",
           alt: "X"
         },{
            name: 'Img4',
            class: "webPhoto",
            imagesrc: "images/architecture/marketmall/marketmall___6.jpg",
            alt: "X"
          }, {
            name: 'Img4',
            class: "webPhoto",
            imagesrc: "images/architecture/marketmall/marketmall___7.jpg",
            alt: "X"
          },{
            name: 'Img4',
            class: "webPhoto",
            imagesrc: "images/architecture/marketmall/marketmall___8.jpg",
            alt: "X"
          }, {
            name: 'Img4',
            class: "webPhoto",
            imagesrc: "images/architecture/marketmall/marketmall___9.jpg",
            alt: "X"
          },{
            name: 'Img4',
            class: "webPhoto",
            imagesrc: "images/architecture/marketmall/marketmall___10.jpg",
            alt: "X"
          },{
            name: 'Img4',
            class: "webPhoto",
            imagesrc: "images/architecture/marketmall/marketmall___11.jpg",
            alt: "X"
          },{
            name: 'Img4',
            class: "webPhoto",
            imagesrc: "images/architecture/marketmall/marketmall___12.jpg",
            alt: "X"
          },{
            name: 'Img4',
            class: "webPhoto",
            imagesrc: "images/architecture/marketmall/marketmall___13.jpg",
            alt: "X"
          },{
            name: 'Img4',
            class: "webPhoto",
            imagesrc: "images/architecture/marketmall/marketmall___14.jpg",
            alt: "X"
          }
        ];
        export const aventuraData = [
          {
              name: 'Img4',
              class: "webPhoto",
              imagesrc: "images/architecture/aventura/aventura___1.png",
              alt: "X"
            }, {
              name: 'Img4',
              class: "webPhoto",
              imagesrc: "images/architecture/aventura/aventura___2.jpg",
              alt: "X"
            }, {
              name: 'Img4',
              class: "webPhoto",
              imagesrc: "images/architecture/aventura/aventura___3.jpg",
              alt: "X"
            },{
              name: 'Img4',
              class: "webPhoto",
              imagesrc: "images/architecture/aventura/aventura___4.jpg",
              alt: "X"
            },{
             name: 'Img4',
             class: "webPhoto",
             imagesrc: "images/architecture/aventura/aventura___5.jpg",
             alt: "X"
           },{
              name: 'Img4',
              class: "webPhoto",
              imagesrc: "images/architecture/aventura/aventura___6.jpg",
              alt: "X"
            }, {
              name: 'Img4',
              class: "webPhoto",
              imagesrc: "images/architecture/aventura/aventura___7.jpg",
              alt: "X"
            },{
              name: 'Img4',
              class: "webPhoto",
              imagesrc: "images/architecture/aventura/aventura___8.jpg",
              alt: "X"
            }, {
              name: 'Img4',
              class: "webPhoto",
              imagesrc: "images/architecture/aventura/aventura___9.jpg",
              alt: "X"
            },{
              name: 'Img4',
              class: "webPhoto",
              imagesrc: "images/architecture/aventura/aventura___10.jpg",
              alt: "X"
            },{
              name: 'Img4',
              class: "webPhoto",
              imagesrc: "images/architecture/aventura/aventura___11.jpg",
              alt: "X"
            },{
              name: 'Img4',
              class: "webPhoto",
              imagesrc: "images/architecture/aventura/aventura___12.jpg",
              alt: "X"
            },{
              name: 'Img4',
              class: "webPhoto",
              imagesrc: "images/architecture/aventura/aventura___13.jpg",
              alt: "X"
            }
          ];
          export const MECData = [
            {
                name: 'Img4',
                class: "webPhoto",
                imagesrc: "images/architecture/MEC/MEC___1.jpg",
                alt: "X"
              }, {
                name: 'Img4',
                class: "webPhoto",
                imagesrc: "images/architecture/MEC/MEC___2.jpg",
                alt: "X"
              },{
                name: 'Img4',
                class: "webPhoto",
                imagesrc: "images/architecture/MEC/MEC___4.jpg",
                alt: "X"
              },{
               name: 'Img4',
               class: "webPhoto",
               imagesrc: "images/architecture/MEC/MEC___5.jpg",
               alt: "X"
             },{
                name: 'Img4',
                class: "webPhoto",
                imagesrc: "images/architecture/MEC/MEC___6.jpg",
                alt: "X"
              }, {
                name: 'Img4',
                class: "webPhoto",
                imagesrc: "images/architecture/MEC/MEC___7.jpg",
                alt: "X"
              },{
                name: 'Img4',
                class: "webPhoto",
                imagesrc: "images/architecture/MEC/MEC___8.jpg",
                alt: "X"
              }, {
                name: 'Img4',
                class: "webPhoto",
                imagesrc: "images/architecture/MEC/MEC___9.jpg",
                alt: "X"
              },{
                name: 'Img4',
                class: "webPhoto",
                imagesrc: "images/architecture/MEC/MEC___10.jpg",
                alt: "X"
              },{
                name: 'Img4',
                class: "webPhoto",
                imagesrc: "images/architecture/MEC/MEC___11.jpg",
                alt: "X"
              },{
                name: 'Img4',
                class: "webPhoto",
                imagesrc: "images/architecture/MEC/MEC___12.jpg",
                alt: "X"
              },{
                name: 'Img4',
                class: "webPhoto",
                imagesrc: "images/architecture/MEC/MEC___13.jpg",
                alt: "X"
              },{
                name: 'Img4',
                class: "webPhoto",
                imagesrc: "images/architecture/MEC/MEC___14.jpg",
                alt: "X"
              },{
                name: 'Img4',
                class: "webPhoto",
                imagesrc: "images/architecture/MEC/MEC___15.jpg",
                alt: "X"
              },{
                name: 'Img4',
                class: "webPhoto",
                imagesrc: "images/architecture/MEC/MEC___16.jpg",
                alt: "X"
              }
            ];

            export const santamonicaData = [
              {
                  name: 'Img4',
                  class: "webPhoto",
                  imagesrc: "images/architecture/santamonica/santamonica___1.jpg",
                  alt: "X"
                }, {
                  name: 'Img4',
                  class: "webPhoto",
                  imagesrc: "images/architecture/santamonica/santamonica___2.jpg",
                  alt: "X"
                }, {
                  name: 'Img4',
                  class: "webPhoto",
                  imagesrc: "images/architecture/santamonica/santamonica___3.jpg",
                  alt: "X"
                },{
                  name: 'Img4',
                  class: "webPhoto",
                  imagesrc: "images/architecture/santamonica/santamonica___4.jpg",
                  alt: "X"
                },{
                 name: 'Img4',
                 class: "webPhoto",
                 imagesrc: "images/architecture/santamonica/santamonica___5.jpg",
                 alt: "X"
               },{
                  name: 'Img4',
                  class: "webPhoto",
                  imagesrc: "images/architecture/santamonica/santamonica___6.jpg",
                  alt: "X"
                }, {
                  name: 'Img4',
                  class: "webPhoto",
                  imagesrc: "images/architecture/santamonica/santamonica___7.jpg",
                  alt: "X"
                },{
                  name: 'Img4',
                  class: "webPhoto",
                  imagesrc: "images/architecture/santamonica/santamonica___8.jpg",
                  alt: "X"
                }, {
                  name: 'Img4',
                  class: "webPhoto",
                  imagesrc: "images/architecture/santamonica/santamonica___9.jpg",
                  alt: "X"
                },{
                  name: 'Img4',
                  class: "webPhoto",
                  imagesrc: "images/architecture/santamonica/santamonica___10.jpg",
                  alt: "X"
                },{
                  name: 'Img4',
                  class: "webPhoto",
                  imagesrc: "images/architecture/santamonica/santamonica___11.jpg",
                  alt: "X"
                },{
                  name: 'Img4',
                  class: "webPhoto",
                  imagesrc: "images/architecture/santamonica/santamonica___12.jpg",
                  alt: "X"
                },{
                  name: 'Img4',
                  class: "webPhoto",
                  imagesrc: "images/architecture/santamonica/santamonica___13.jpg",
                  alt: "X"
                },{
                  name: 'Img4',
                  class: "webPhoto",
                  imagesrc: "images/architecture/santamonica/santamonica___14.jpg",
                  alt: "X"
                }
              ];
