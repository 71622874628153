import React from 'react';

export const Images = props => {
	const { item } = props;
	return (
		<div ng-repeat="image in store.images.slice(0, 1)">
			<div className={item.class}>
				<img
					src={require(`../../../assets/${item.imagesrc}`)}
					width="100%"
					alt={item.alt}
				/>
			</div>
		</div>
	);
};
