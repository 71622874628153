import React, { Component } from 'react';
import Aux from '../../../hoc/_Aux/_Aux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Images } from '../Images/Images';
import { MECData } from '../../../data/app_data';
import MECHero from '../../../assets/images/architecture/MEC/MEC___16.jpg';

const MEC = props => {
	let MECImages = MECData.map((item, index) => {
		return <Images item={item} key={index} />;
	});

		return (
			<Aux>
				<Helmet>
					<title>LIC - MEC</title>
					<meta name="description" content="NIKE MEC" />
				</Helmet>
				  <div className="intro w1">
				    <h4 className="title ">NIKE MEC</h4>
				    <h1 className="brandDescription ">705 Saint-Catherine St<br/> Montreal, QC </h1>
				    <div className="categories">
				      <h5>3D MODELING </h5>
				      <h5>TECHNICAL DRAWINGS</h5>
				      <h5>FURNITURE</h5>
				      <h5>RENDER</h5>
				    </div>
				  </div>
				  <img src={MECHero} width="100%" alt="NIKE MEC" />
				  <div className="boundingColor">
				  <h2 className="categoryTitle blackText w2">The Project<span>Completed 2023</span></h2>
				  <p className="projectCopy blackText w2 m-b-60">
				  The strategy for this door was adapting the Rise concept and leveraging the knowledge from Nike Owned stores to the Nike Partner stores in the Canada marketplace. This store is operated by Tel Aviv-based Fox Group, who partnered with Nike Canada to set up a chain of Canadian Nike stores.

This flagship store replaced a large multi-level Gap store at Montreal Eaton Centre in downtown Montreal. It boasts prominent frontage on Ste-Catherine Street West, and spans around 21,000 sf between the street-level and a level above.

My role was to onboard design vendors, share our process and best practices, provide design feedback and tools to optimize their process and push towards delivering Nike Canada retail stores at scale. 

My responsibilities included Concept design, initial space planning, fixture program, development and design, brand story and experience, and service journey. Within Design development, my responsibilities included fixture coordination, design handoff, architectural detailing & redlining, and schedule/budget alignment.
</p>
				</div>
				    {MECImages}
				<div className="l-w0 m-mobileProjLink">
					<Link className="m-projLink" to="/SantaMonica">
						Next Project
					</Link>
				</div>
			</Aux>
		);
	};

export default MEC;
