import React, { Component } from 'react';
import Aux from '../../../hoc/_Aux/_Aux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Images } from '../Images/Images';
import { AtlantaData } from '../../../data/app_data';
import AtlantaHero from '../../../assets/images/architecture/atlanta/atlanta___3.jpg';

const Atlanta = props => {
	let AtlantaImages = AtlantaData.map((item, index) => {
		return <Images item={item} key={index} />;
	});

		return (
			<Aux>
				<Helmet>
					<title>LIC - Atlanta</title>
					<meta name="description" content="San Francisco" />
				</Helmet>
				  <div className="intro w1">
				    <h4 className="title ">NIKE ATLANTA - HOUSE OF SPORT</h4>
				    <h1 className="brandDescription ">3393 Peachtree Rd<br/>
Atlanta, GA 30326
</h1>
				    <div className="categories">
				      <h5>3D MODELING </h5>
				      <h5>TECHNICAL DRAWINGS</h5>
				      <h5>FURNITURE</h5>
				      <h5>RENDER</h5>
				    </div>
				  </div>
				  <img src={AtlantaHero} width="100%" alt="Athletic Studio" />
				  <div className="boundingColor">
				  <h2 className="categoryTitle blackText w2">The Project<span>Completed 2019</span></h2>
				  <p className="projectCopy blackText w2 m-b-60">
					Atlanta was a store refresh converting the Nike Fieldhouse concept design to House of Sport.
I was involved with the initial store concept storytelling and worked closely with the visual merchandising team, space planning, and layout of the category apparel and footwear fixtures. I created presentation materials for internal creative, as well as assisted with the onboarding of the architect, handoff of architecture design intent, outlining rules and responsibilities, and redlining architectural drawings sets through construction. As for fixture design, I led this effort on this project, which included biweekly meetings, supervising design from intent through install, and multiple onsite walkthroughs prior to store opening. I traveled to Atlanta multiple times for site walkthroughs and to oversee store design vendors and other construction for the store reopening </p>
				</div>
				    {AtlantaImages}
				<div className="l-w0 m-mobileProjLink">
					<Link className="m-projLink" to="/SanFrancisco">
					Next Project
					</Link>
				</div>
			</Aux>
		);
	};

export default Atlanta;
