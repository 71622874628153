import React, { Component } from 'react';
import Aux from '../../../hoc/_Aux/_Aux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Images } from '../Images/Images';
import { marketmallData } from '../../../data/app_data';
import MKHero from '../../../assets/images/architecture/marketmall/marketmall___2.jpg';


const marketmall = props => {
	let marketmallImages = marketmallData.map((item, index) => {
		return <Images item={item} key={index} />;
	});

		return (
			<Aux>
				<Helmet>
					<title>LIC - marketmall</title>
					<meta name="description" content="NIKE marketmall" />
				</Helmet>
				  <div className="intro w1">
				    <h4 className="title ">NIKE market mall</h4>
				    <h1 className="brandDescription ">3625 Shaganappi Trail <br/> Calgary, Alberta</h1>
				    <div className="categories">
				      <h5>3D MODELING </h5>
				      <h5>TECHNICAL DRAWINGS</h5>
				      <h5>FURNITURE</h5>
				      <h5>RENDER</h5>
				    </div>
				  </div>
				  <img src={MKHero} width="100%" alt="NIKE AV" />

				  <div className="boundingColor">
				  <h2 className="categoryTitle blackText w2">The Project<span>Completed TKTK</span></h2>
				  <p className="projectCopy blackText w2 m-b-60">
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
				</div>
				    {marketmallImages}
				<div className="l-w0 m-mobileProjLink">
					<Link className="m-projLink" to="/MEC">
						Next Project
					</Link>
				</div>
			</Aux>
		);
	};

export default marketmall;
