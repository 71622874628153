import React, { Component } from 'react';
import Aux from '../../../hoc/_Aux/_Aux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Images } from '../Images/Images';
import { aventuraData } from '../../../data/app_data';
import AVHero from '../../../assets/images/architecture/aventura/aventura___7.jpg';


const Aventura = props => {
	let AventuraImages = aventuraData.map((item, index) => {
		return <Images item={item} key={index} />;
	});

		return (
			<Aux>
				<Helmet>
					<title>LIC - AVENTURA</title>
					<meta name="description" content="AVENTURA" />
				</Helmet>
				  <div className="intro w1">
				    <h4 className="title ">NIKE AVENTURA</h4>
				    <h1 className="brandDescription ">19501 Biscayne Blvd <br/> Aventura, FL 33180</h1>
				    <div className="categories">
				      <h5>3D MODELING </h5>
				      <h5>TECHNICAL DRAWINGS</h5>
				      <h5>FURNITURE</h5>
				      <h5>RENDER</h5>
				    </div>
				  </div>
				  <img src={AVHero} width="100%" alt="NIKE AV" />
				  <div className="boundingColor">
				  <h2 className="categoryTitle blackText w2">The Project<span>Completed 2022</span></h2>
				  <p className="projectCopy blackText w2 m-b-60">
				  Nike Aventura is a sport-led retail experience designed to inspire, connect, and serve athletes of all ages and genders. As Nike’s First Rise Concept in North America at Aventura Mall, this was part of an effort to develop and redefine the Rise concept for the North America Marketplace. The expansive two-story mall location is one of the most digitally advanced stores to date, and its atrium is a testament to the brand’s imaginative digital experiences. The atrium’s towering two-floor LED screens play brand campaigns, real-time sports moments, and highlights from local members using the Nike apps. 
<br/><br/>
At the Nike Sports Hub, Nike Members get access to digital returns, in-store pickups for online orders, curbside pickup, and a scan-to-learn feature for products. As well, the SNKRS Pass is a reservation system helps sneakerheads get their hands on exclusive styles. Nike By You is a dedicated space where shoppers can express their creativity and work one-on-one with a Nike By You expert to personalize shoes and apparel. Aventura Mall is the second location in North America to feature a Nike By You station. This store further infuzes local culture by partnered with local artist Ruth Burotte to create designs that deliver all the best Miami-inspired customizations in the Nike By You department.
<br/><br/>
My initial responsibilities included initial space planning, fixture program design and development, brand story and experience, and service journey, and later included design handoff and architectural detailing & redlining.
</p>
				</div>
				    {AventuraImages}
				<div className="l-w0 m-mobileProjLink">
					<Link className="m-projLink" to="/MarketMall">
						Next Project
					</Link>
				</div>
			</Aux>
		);
	};

export default Aventura;
