import React, { Component } from 'react';
import Aux from '../../../hoc/_Aux/_Aux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Images } from '../Images/Images';
import { FWDData } from '../../../data/app_data';
import FWDHero from '../../../assets/images/architecture/fwd/fwd___1.jpg';


const FWD = props => {
	let FWDImages = FWDData.map((item, index) => {
		return <Images item={item} key={index} />;
	});

		return (
			<Aux>
				<Helmet>
					<title>LIC - Furniture</title>
					<meta name="description" content="Furniture" />
				</Helmet>
				  <div className="intro w1">
				    <h4 className="title ">FURNITURE</h4>
				    <h1 className="brandDescription ">Fieldwork Design/Other</h1>
				    <div className="categories">
				      <h5>3D MODELING </h5>
				      <h5>TECHNICAL DRAWINGS</h5>
				      <h5>FURNITURE</h5>
				      <h5>RENDER</h5>
				    </div>
				  </div>
				  <img src={FWDHero} width="100%" alt="Athletic Studio" />
				  <div className="boundingColor">
				  <h2 className="categoryTitle blackText w2">The Project</h2>
				  <p className="projectCopy blackText w2 m-b-60">
				   While at Fieldwork Design, I took the lead in designing this one-off screen with a coat rack located at the entrance of our client’s Mid-Century Modern home. The individual hook was inspired by the arm of a clothes hanger and we wanted to highlight the hooks by using polished brass. It served as an art piece at the entrance and as a divider between the foyer and the stairs going down to the basement. My role on this project was to create the technical drawings, produce the 3-D model, create renderings of the piece in the space, source the brass tubes and collaborate with a metal specialist.</p>
				</div>
				    {FWDImages}
				<div className="l-w0 m-mobileProjLink">
					<Link className="m-projLink" to="/about">
					About Me
					</Link>
				</div>
			</Aux>
		);
	};

export default FWD;
