const contents = [
	{
		name: 'Retail',
		class: "project1",
		description: "Chicago Peg 41",
		height: "img2",
		link: "/ChiPeg",
		imagesrc: "images/home/chipeg.jpg",
		alt: "Chi Peg"
	},{
			name: 'Retail',
			class: "project1",
			description: "Aventura",
			height: "img2",
			link: "/Aventura",
			imagesrc: "images/home/aventura.jpg",
			alt: "Aventura"
		},{
	name: 'Retail',
	class: "project1",
	description: "MEC",
	height: "img2",
	link: "/MEC",
	imagesrc: "images/home/MEC.jpg",
	alt: "MEC"
},{
name: 'Retail',
class: "project1",
description: "Santa Monica",
height: "img2",
link: "/SantaMonica",
imagesrc: "images/home/santamonica.jpg",
alt: "Santa Monica"
},{
			name: 'Retail',
			class: "project1",
			description: "Upper East Side",
			height: "img2",
			link: "/UES",
			imagesrc: "images/home/ues.jpg",
			alt: "UES"
		},
	{
		name: 'Retail',
		class: "project1",
		description: "Eugene",
		height: "img2",
		link: "/Eugene",
		imagesrc: "images/home/eugene.jpg",
		alt: "Eugene"
},
		{
		name: 'Retail',
		class: "project1",
		description: "Atlanta",
		height: "img2",
		link: "/Atlanta",
		imagesrc: "images/home/atl.jpg",
		alt: "ATL"
	},{
		name: 'Retail',
		class: "project1",
		description: "San Francisco",
		height: "img2",
		link: "/SanFrancisco",
		imagesrc: "images/home/sf.jpg",
		alt: "Black Table"
	}, {
		name: 'Retail',
		class: "project1",
		description: "Chicago",
		height: "img2",
		link: "/chicago",
		imagesrc: "images/home/chicago.jpg",
		alt: "Black Table"
	}, 	{
	    name: 'Retail',
	    class: "project1",
	    description: "Virgil",
	    height: "img2",
	    link: "/Virgil",
	    imagesrc: "images/home/virgil.jpg",
	    alt: "X"
	  }, {
    name: 'Retail',
    class: "project1",
    description: "Sherway Gardens",
    height: "img2",
    link: "/Sherway",
    imagesrc: "images/home/sherway.jpg",
    alt: "X"
  },{
    name: 'Retail',
    class: "project1",
    description: "Square One",
    height: "img2",
    link: "/SQ1",
    imagesrc: "images/home/SQ1.jpg",
    alt: "X"
  },
	{
    name: 'Retail',
    class: "project1",
    description: "Soho",
    height: "img2",
    link: "/soho",
    imagesrc: "images/home/soho.jpg",
    alt: "Black Table"
  },{
    name: 'Design Studio',
    class: "project1",
    description: "Blue Ribbon Studio",
    height: "img2",
    link: "/athletic",
    imagesrc: "images/home/athletic.jpg",
    alt: "Athletic Design Studio"
  }, {
    name: 'Residential',
    class: "project1",
    description: "Carriage House",
    height: "img2",
    link: "/carriagehouse",
    imagesrc: "images/home/carriage.jpg",
    alt: "X"
  }, {
name: 'Residential',
class: "project1",
description: "Carriage House 2",
height: "img2",
link: "/carriagehouse2",
imagesrc: "images/home/carriage2.jpg",
alt: "X"
}]



export default contents;
