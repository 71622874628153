import React from 'react';
import Aux from '../../../hoc/_Aux/_Aux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Images } from '../Images/Images';
import { santamonicaData } from '../../../data/app_data';

const SantaMonica = props => {
	let SantaMonicaImages = santamonicaData.map((item, index) => {
		return <Images item={item} key={index} />;
	});

		return (
			<Aux>
				<Helmet>
					<title>LIC - SantaMonica</title>
					<meta name="description" content="NIKE Santa Monica" />
				</Helmet>
				  <div className="intro w1">
				    <h4 className="title ">NIKE Santa Monica</h4>
				    <h1 className="brandDescription ">395 Santa Monica Pl <br/> Santa Monica, CA 90401</h1>
				    <div className="categories">
				      <h5>3D MODELING </h5>
				      <h5>TECHNICAL DRAWINGS</h5>
				      <h5>FURNITURE</h5>
				      <h5>RENDER</h5>
				    </div>
				  </div>
				  <div className="boundingColor">
				  <h2 className="categoryTitle blackText w2">The Project<span>Completed TKTK</span></h2>
				  <p className="projectCopy blackText w2 m-b-60">
				  Our team facilitated this massive renovation, pivoting the 20,000-square-foot space to showcase Nikes new Rise retail concept, centered around a renewed focus on women and Nike+ services, including Nike+ Run Analysis, bra fitting, pant hemming, and personal shopping. The store features a curated product assortment focused on running, fitness, and basketball favorites for the full family. </p>

				</div>
				    {SantaMonicaImages}
				<div className="l-w0 m-mobileProjLink">
					<Link className="m-projLink" to="/UES">
						Next Project
					</Link>
				</div>
			</Aux>
		);
	};

export default SantaMonica;
