import React, { Component } from 'react';
import Aux from '../../../hoc/_Aux/_Aux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Images } from '../Images/Images';
import { EugeneData } from '../../../data/app_data';
import EugeneHero from '../../../assets/images/architecture/eugene/eugene___5.jpg';

const Eugene = props => {
	let EugeneImages = EugeneData.map((item, index) => {
		return <Images item={item} key={index} />;
	});

		return (
			<Aux>
				<Helmet>
					<title>LIC - Eugene</title>
					<meta name="description" content="Eugene Nike" />
				</Helmet>
				  <div className="intro w1">
				    <h4 className="title ">NIKE Eugene - LIVE</h4>
				    <h1 className="brandDescription ">590 Pearl St	<br/>
Eugene, OR 97401
</h1>
				    <div className="categories">
				      <h5>3D MODELING </h5>
				      <h5>TECHNICAL DRAWINGS</h5>
				      <h5>FURNITURE</h5>
				      <h5>RENDER</h5>
				    </div>
				  </div>
				  <img src={EugeneHero} width="100%" alt="Athletic Studio" />
				  <div className="boundingColor">
				  <h2 className="categoryTitle blackText w2">The Project<span>Completed 2021</span></h2>
				  <p className="projectCopy blackText w2 m-b-60">
					Eugene is the birthplace of Nike. As this is its only store inn its hometown, we had the unique responsibility if weaving Nike's origin story together with the narratives of Nike’s leading innovations. We focused on keeping this store uniquely of the place by using local materials like end-grain doug fir flooring and reclaimed Hayward field beams, as well as imagery of Nike's beginnings.<br/><br/>

	The store occupies a downtown mixed-use building's first floor space, made unique by a double height atrium and double wide entry. I was the lead designer for the entirety of this project, from insight to concept to design handoff. Our process began by collaborating with space planning and visual merchandising to lay out the space. Beyond my typical design responsibilities, I also collaborated with environmental graphic design on the rugs and curtains.<br/><br/>

	This project was in partnership with Nike’s internal Store Design, Visual Merchandising, Brand Marketing (NSO and Live), Space Planning, Environmental Graphic Design teams as well as external teams including Superette (Architect of Record) and RTC (fixture vendor). Collaboration with external teams was a special challenge due to COVID-19; our team pivoted to digital collaboration mediums, including virtual site visits and zoom meetings.

</p>
				</div>
				    {EugeneImages}
				<div className="l-w0 m-mobileProjLink">
					<Link className="m-projLink" to="/atlanta">
					Next Project
					</Link>
				</div>
			</Aux>
		);
	};

export default Eugene;
