import React, { Component } from 'react';
import Aux from '../../../hoc/_Aux/_Aux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Images } from '../Images/Images';
import { AthleticData } from '../../../data/app_data';
import athleticHero from '../../../assets/images/architecture/athletic/athletic-1.jpg';

const Athletic = props => {
	let AthleticImages = AthleticData.map((item, index) => {
		return <Images item={item} key={index} />;
	});

		return (
			<Aux>
				<Helmet>
					<title>LIC - Athletic</title>
					<meta name="description" content="Athletic" />
				</Helmet>
				  <div className="intro w1">
				    <h4 className="title ">NIKE BLUE RIBBON STUDIO</h4>
				    <h1 className="brandDescription ">One Bowerman Dr <br/> Beaverton, OR 97005</h1>
				    <div className="categories">
				      <h5>3D MODELING </h5>
				      <h5>TECHNICAL DRAWINGS</h5>
				      <h5>FURNITURE</h5>
				      <h5>RENDER</h5>
				    </div>
				  </div>
				  <img src={athleticHero} width="100%" alt="Athletic Studio" />
				  <div className="boundingColor">
				  <h2 className="categoryTitle blackText w2">The Project <span>Completed 2015</span></h2>
				  <p className="projectCopy blackText w2 m-b-60">
				    While at Fieldwork Design, I assisted with the design of the Blue Ribbon Studio, a Nike-sponsored space for designers to be inspired, create and collaborate with teams outside of their own categories. This building houses a sewing workshop, 3-D printing lab, silkscreen printing studio, library project space and collaborative design office. My tasks on this project included producing the building 3d model, developing technical interior drawings, creating renderings, and producing shop drawings.</p>
				</div>
				    {AthleticImages}
				<div className="l-w0 m-mobileProjLink">
					<Link className="m-projLink" to="/carriagehouse">
					Next Project
					</Link>
				</div>
			</Aux>
		);
	};

export default Athletic;
