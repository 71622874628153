import React, { Component } from 'react';
import Aux from '../../../hoc/_Aux/_Aux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Images } from '../Images/Images';
import { KiddomData } from '../../../data/app_data';
import kiddomHero from '../../../assets/images/architecture/kiddom/kiddom___2.jpg';


const Kiddom = props => {
	let KiddomImages = KiddomData.map((item, index) => {
		return <Images item={item} key={index} />;
	});

		return (
			<Aux>
				<Helmet>
					<title>LIC - Kiddom</title>
					<meta name="description" content="Kiddom" />
				</Helmet>
				  <div className="intro w1">
					<h4 className="title ">Kiddom</h4>
			    <h1 className="brandDescription">San Francisco under-priveledged after school facility</h1>
			    <div className="categories">
			      <h5>CONCEPT</h5>
			      <h5>3D MODELING</h5>
			      <h5>TECHNICAL DRAWINGS</h5>
			      <h5>RENDER</h5>
				    </div>
				  </div>
					<img src={kiddomHero} width="100%" alt="Historic Row House Photo" />
				  <div className="boundingColor">
				<h2 className="categoryTitle blackText w2">The Project</h2>
				    <p className="projectCopy blackText w2 m-b-60">The California School system continues to fall victim to tax cuts, revealing gaps in art, drama, and music programs, as well as a lack of computers and textbooks. I am proposing KID-DOM, an afterschool program which gives children unique mediums through which they can learn, express themselves, and release physical energy. KID-DOM will cater to around 200 children ages 5-14. KID-DOM explores how spaces can encourage creativity, learning, and activity, and foster comfortable and natural interactions. The center will also provide a supplement to school through individualized tutoring, hands-on learning, and incorporating technology to encourage active engagement in topics. Through exercise, artful expression, and heightened learning experiences, I hope that the children will discover self-worth.</p>
				  </div>
{KiddomImages}
				<div className="l-w0 m-mobileProjLink">
					<Link className="m-projLink" to="/crypto">
					Next Project
					</Link>
				</div>
			</Aux>
		);
	};

export default Kiddom;
