import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Aux from '../../hoc/_Aux/_Aux';
import resumePath from '../../assets/images/codyLuke_resume.pdf';

const about = props => {
	return (
		<Aux>
			<Helmet>
				<title>About | Cody</title>
				<meta name="description" content="Dan Beltramo - About Me" />
			</Helmet>
				<div className="m-indexText w1">

				<p className="projectCopy blackText">
				Cody Luke was born and raised in San Francisco, and is working as a Senior Retail Designer for Nike in Portland OR. She is a strategic Lead Retail Store Designer with a proven track record of conceiving and
delivering exceptional retail experiences. Skilled in cross-functional leadership,
architectural design, fixture development, and exceeding client expectations with
innovative concepts. She is well-versed in developing design concepts and interior details for high-end retail environments. She loves to stay up to date with current trends, color, technology, and related design influencers.</p>
				<div className="resume m-t-20 m-b-90">
					<a href={resumePath} target="_blank" rel="noopener noreferrer">
						<p className="pink">Resume</p>
					</a>
			</div>
			</div>
		</Aux>
	);
};

export default about;
