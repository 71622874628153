import React, { Component } from 'react';
import Aux from '../../../hoc/_Aux/_Aux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Images } from '../Images/Images';
import { SQ1Data } from '../../../data/app_data';
import SQ1Hero from '../../../assets/images/architecture/sq1/sq1___1.jpg';

const Studio = props => {
	let SQ1Images = SQ1Data.map((item, index) => {
		return <Images item={item} key={index} />;
	});

		return (
			<Aux>
				<Helmet>
					<title>LIC - SQ1</title>
					<meta name="description" content="SQ1" />
				</Helmet>
				  <div className="intro w1">
				    <h4 className="title ">NIKE - SQUARE ONE</h4>
				    <h1 className="brandDescription ">100 City Centre Dr <br/> Mississauga, ON</h1>
				    <div className="categories">
				      <h5>3D MODELING </h5>
				      <h5>TECHNICAL DRAWINGS</h5>
				      <h5>FURNITURE</h5>
				      <h5>RENDER</h5>
				    </div>
				  </div>
				  <img src={SQ1Hero} width="100%" alt="Athletic Studio" />
				  <div className="boundingColor">
				  <h2 className="categoryTitle blackText w2">The Project <span>Completed 2018</span></h2>
				  <p className="projectCopy blackText w2 m-b-60">
				   Square One was our sixth NSP door and our objective was similar to Sherway Gardens. My role was in space planning, the design of the double height facade, creating the layout of the product categories (in alignment with the business), and fixture design.</p>
				</div>
				    {SQ1Images}
				<div className="l-w0 m-mobileProjLink">
					<Link className="m-projLink" to="/soho">
					Next Project
					</Link>
				</div>
			</Aux>
		);
	};

export default Studio;
