import React, { Component } from 'react';
import Aux from '../../../hoc/_Aux/_Aux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Images } from '../Images/Images';
import { SherwayData } from '../../../data/app_data';
import SherwayHero from '../../../assets/images/architecture/sherway/sherway___1.jpg';

const Studio = props => {
	let SherwayImages = SherwayData.map((item, index) => {
		return <Images item={item} key={index} />;
	});

		return (
			<Aux>
				<Helmet>
					<title>LIC - Sherway</title>
					<meta name="description" content="Sherway" />
				</Helmet>
				  <div className="intro w1">
				    <h4 className="title ">NIKE - SHERWAY GARDENS</h4>
				    <h1 className="brandDescription ">Etobicoke <br/> Toronto, ON</h1>
				    <div className="categories">
				      <h5>3D MODELING </h5>
				      <h5>TECHNICAL DRAWINGS</h5>
				      <h5>FURNITURE</h5>
				      <h5>RENDER</h5>
				    </div>
				  </div>
				  <img src={SherwayHero} width="100%" alt="Athletic Studio" />
				  <div className="boundingColor">
				  <h2 className="categoryTitle blackText w2">The Project <span>Completed 2017</span></h2>
				  <p className="projectCopy blackText w2 m-b-60">
				  Sherway Gardens was our first NSP door and our objective was to inspire and enable the local athlete through the lens of the Nike Brand and the lifestyle of Modern Sport. My role was to design the double height facade, space planning and laying out the product categories to align with the business and fixture design.</p>
				</div>
				    {SherwayImages}
				<div className="l-w0 m-mobileProjLink">
					<Link className="m-projLink" to="/sq1">
					Next Project
					</Link>
				</div>
			</Aux>
		);
	};

export default Studio;
