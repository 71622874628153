import React, { Component } from 'react';
import Aux from '../../../hoc/_Aux/_Aux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Images } from '../Images/Images';
import { VirgilData } from '../../../data/app_data';
import VirgilHero from '../../../assets/images/architecture/virgil/virgil___11.jpg';

const Studio = props => {
	let VirgilImages = VirgilData.map((item, index) => {
		return <Images item={item} key={index} />;
	});

		return (
			<Aux>
				<Helmet>
					<title>LIC - Virgil</title>
					<meta name="description" content="Virgil" />
				</Helmet>
				  <div className="intro w1">
				    <h4 className="title ">NIKELAB CHICAGO - VIRGIL</h4>
				    <h1 className="brandDescription ">673 N Michigan Avenue
Chicago, IL 60611
</h1>
				    <div className="categories">
				      <h5>3D MODELING </h5>
				      <h5>TECHNICAL DRAWINGS</h5>
				      <h5>FURNITURE</h5>
				      <h5>RENDER</h5>
				    </div>
				  </div>
				  <img src={VirgilHero} width="100%" alt="Athletic Studio" />
				  <div className="boundingColor">
				  <h2 className="categoryTitle blackText w2">The Project <span>Completed 2019</span></h2>
				  <p className="projectCopy blackText w2 m-b-60">
				   While working on the Nike Chicago store, I took on a stretch with the Retail Concept Design team as a store design consultant. The design concept was to reinvent NikeLab Chicago and provide a platform for creativity in the Chicago community. Virgil wanted to create a disruptive experience visible from the street, distorting retail by exposing the process and transform the space by turning everything around. I was able to work quickly on design iterations drawing inspiration on various BOH facilities and studying materiality through a Nike Sport and Chicago lens.</p>
				</div>
				    {VirgilImages}
				<div className="l-w0 m-mobileProjLink">
					<Link className="m-projLink" to="/sherway">
					Next Project
					</Link>
				</div>
			</Aux>
		);
	};

export default Studio;
