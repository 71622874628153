import React, { Component } from 'react';
import Aux from '../../../hoc/_Aux/_Aux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Images } from '../Images/Images';
import { CheeseData } from '../../../data/app_data';
import cheeseHero from '../../../assets/images/architecture/cheese/cheese-1.jpg';

const Studio = props => {
	let CheeseImages = CheeseData.map((item, index) => {
		return <Images item={item} key={index} />;
	});

		return (
			<Aux>
				<Helmet>
					<title>LIC - Cheese</title>
					<meta name="description" content="Cheese" />
				</Helmet>
				  <div className="intro w1">
					<h4 className="title ">CULTURED CHEESE SHOP</h4>
			    <h1 className="brandDescription ">Eugene, Oregon</h1>
			    <div className="categories">
			      <h5>CONCEPT</h5>
			      <h5>WORKING DRAWINGS</h5>
			      <h5>3D MODELING</h5>
			      <h5>RENDER</h5>
			    </div>
				  </div>
<img src={cheeseHero} width="100%" alt="Soho Retail"/>
				  <div className="boundingColor">
					<h2 className="categoryTitle big blackText w2">The Project</h2>
			    <p className="projectCopy blackText w2 m-b-60">This design project is a retrofit of an existing office space into a cheese shop. The design of the cheese shop was greatly inspired by the process of making cheese. As the customers enter the space, the cheese sampling area includes floating tables, designed to loosely resemble cheese curds, the beginning of the cheese-making process. Next, customers encounter the formal cheese tasting and education area, imagined as a space pressed into a form, like a cheese form, and lastly encounter a wall display of the cheeses for sale, resembling the cheese aging process. This retrofit included creating a demolition plan and using the existing roof structure and brick walls to rethink the space. These working drawings include plans, elevations, sections, detailing window enclosures and casework, electrical lighting, and a FF&E schedule.</p>
			  </div>
				    {CheeseImages}
				<div className="l-w0 m-mobileProjLink">
					<Link className="m-projLink" to="/sushibento">
					Next Project
					</Link>
				</div>
			</Aux>
		);
	};

export default Studio;
