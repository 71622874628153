import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import licImage from '../../assets/images/lic.svg';

const initialState = {
  BackButtonMobile: 'hide', 
  about: 'colorFill',
}

const HomeHeader = props => {
  const [classes, setClasses] = useState(initialState);
  const { pathname } = useLocation();

  const getClassnames = (pathname) => {
    switch (pathname) {
   case '/': return setClasses({ BackButtonMobile: 'hide', about: 'colorFill', });
   case '/athletic': return setClasses({ BackButtonMobile: 'colorFill', about: 'hide', });
   case '/atlanta': return setClasses({ BackButtonMobile: 'colorFill', about: 'hide', });
   case '/aventura': return setClasses({ BackButtonMobile: 'colorFill', about: 'hide', });
   case '/carriageHouse': return setClasses({ BackButtonMobile: 'colorFill', about: 'hide', });
   case '/carriageHouse2': return setClasses({ BackButtonMobile: 'colorFill', about: 'hide', });
   case '/cheese': return setClasses({ BackButtonMobile: 'colorFill', about: 'hide', });
   case '/chicago': return setClasses({ BackButtonMobile: 'colorFill', about: 'hide', });
   case '/eugene': return setClasses({ BackButtonMobile: 'colorFill', about: 'hide', });
   case '/fwd': return setClasses({ BackButtonMobile: 'colorFill', about: 'hide', });
   case '/kiddom': return setClasses({ BackButtonMobile: 'colorFill', about: 'hide', });
   case '/marketmall': return setClasses({ BackButtonMobile: 'colorFill', about: 'hide', });
   case '/mec': return setClasses({ BackButtonMobile: 'colorFill', about: 'hide', });
   case '/sanfrancisco': return setClasses({ BackButtonMobile: 'colorFill', about: 'hide', });
   case '/santamonica': return setClasses({ BackButtonMobile: 'colorFill', about: 'hide', });
   case '/sherway': return setClasses({ BackButtonMobile: 'colorFill', about: 'hide', });
   case '/soho': return setClasses({ BackButtonMobile: 'colorFill', about: 'hide', });
   case '/sohostadium': return setClasses({ BackButtonMobile: 'colorFill', about: 'hide', });
   case '/sq1': return setClasses({ BackButtonMobile: 'colorFill', about: 'hide', });
   case '/about': return setClasses({ BackButtonMobile: 'colorFill', about: 'hide', });
   default: return classes;
    }
  }
  useEffect(() => {
    getClassnames(pathname.toLowerCase());
  }, [pathname])

  return (
    <header>
      <nav id="fixedMenu">
      <Link to="/" className="header-left">
      <img src={licImage} style={{ height: '38px', width: '38px' }} alt="logo" />
      </Link>
      <li>
      <Link className={`${classes.Text} colorFill header-right`} to="/about">
      <h2 className={`${classes.about} m-menu-toggle weighty all`}> About </h2>
        </Link>
      </li>
      <Link className={`${classes.Text} colorFill header-right`} to="/">
      <h2 className={`${classes.BackButtonMobile} m-menu-toggle weighty all`}> <span className={`colorFill all material-symbols-rounded`}>keyboard_return</span> All </h2>
      </Link>
      </nav>
    </header>
  );
};

export default HomeHeader;
