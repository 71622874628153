import React, { Component } from 'react';
import Aux from '../../../hoc/_Aux/_Aux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Images } from '../Images/Images';
import { CarriageData } from '../../../data/app_data';
import carriageHero from '../../../assets/images/architecture/carriage/carriage-1.jpg';


const Studio = props => {
	let CarriageHouseImages = CarriageData.map((item, index) => {
		return <Images item={item} key={index} />;
	});

		return (
			<Aux>
				<Helmet>
					<title>LIC - Carriage House</title>
					<meta name="description" content="Carriage House" />
				</Helmet>
				  <div className="intro w1">
				    <h4 className="title ">CARRIAGE HOUSE</h4>
				    <h1 className="brandDescription ">NW PORTLAND, OR</h1>
				    <div className="categories">
				      <h5>3D MODELING </h5>
				      <h5>TECHNICAL DRAWINGS</h5>
				      <h5>FURNITURE</h5>
				      <h5>RENDER</h5>
				    </div>
				  </div>
				  <img src={carriageHero} width="100%" alt="Athletic Studio" />
				  <div className="boundingColor">
				  <h2 className="categoryTitle blackText w2">The Project <span>Completed 2016</span></h2>
				  <p className="projectCopy blackText w2 m-b-60">
				   I served as lead designer at Fieldwork Design on the Carriage House condo renovation in NW Portland, Oregon. The design intention was to preserve the original and unique features of the home. We decided to highlight the space’s unique industrial brick wall, and pair it with warmer materials to create a cozy living room and bedroom. A clean contemporary interior aesthetic was maintained throughout the number of custom furniture pieces and built-ins, and a few classic country accents were incorporated into the kitchen to add a fresh feel to the space.</p>
				</div>
				    {CarriageHouseImages}
				<div className="l-w0 m-mobileProjLink">
					<Link className="m-projLink" to="/carriagehouse2">
					Next Project
					</Link>
				</div>
			</Aux>
		);
	};

export default Studio;
